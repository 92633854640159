import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Button,
  Flex,
  Heading,
  Divider,
  InputGroup,
  InputLeftAddon,
  Select,
  Center,
  VStack,
  Spinner,
  Text,
  Stack,
  Grid,
  CircularProgress,
  CircularProgressLabel,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { getToken } from "../api/get-token-api";
import { useAppContext } from "../context/AppContext";
import { getActiveYear } from "../api/get-active-year";
import { postActiveYear } from "../api/post-active";
import { autoAval } from "../api/auto-aval-api";
import GraficoAnexos from "../components/anexos-contagem-grafico";

const PeriodoAvaliacao = () => {
  // Estados para armazenar os valores dos inputs, erros e mensagem de sucesso da API
  const [ano, setAno] = useState("2023");
  const [referencia, setReferencia] = useState("3");
  const [erroAno, setErroAno] = useState("");
  const [erroTrimestre, setErroTrimestre] = useState("");
  const [token, setToken] = useState("");
  const [apiData, setApiData] = useState([]);
  const [sucessoAPI, setSucessoAPI] = useState(""); // Estado para armazenar a mensagem de sucesso da API
  const [erroAPI, setErroAPI] = useState(""); // Estado para armazenar a mensagem de sucesso da API
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [currentDistribuidor, setCurrentDistribuidor] = useState("");
  const [botaoClicado, setBotaoClicado] = useState(false);
  const [anoAutoAval, setAnoAutoAval] = useState("");
  const [referenciaAutoAval, setReferenciaAutoAval] = useState("");
  const [distribuidorAvaliado, setDistribuidorAvaliado] = useState("");
  const [numeroPEX, setNumeroPEX] = useState("");
  const [codigoDistribuidorAvaliado, setCodigoDistribuidorAvaliado] =
    useState("");
  const [progress, setProgress] = useState(0);
  const [loadingAutoAval, setLoadingAutoAval] = useState(false);
  const [sucessoApiAuto, setSucessoApiAuto] = useState("");
  const [periodoAvaliado, setPeriodoAvaliado] = useState("");
  const { emailGlobal, setEmailGlobal } = useAppContext();
  const { passwordGlobal, setPasswordGlobal } = useAppContext();
  const { anoGlobal, setAnoGlobal } = useAppContext();
  const { trimestreGlobal, setTrimestreGlobal } = useAppContext();
  const { statusGlobal, setStatusGlobal } = useAppContext();

  useEffect(() => {
    const fetchActiveYear = async () => {
      try {
        setLoading(true); // Ativa o loading
        // Obter o token
        const tokenData = await getToken(emailGlobal, passwordGlobal);
        setToken(tokenData.access_token);
        // Obter os itens da API usando o token
        const itemsData = await getActiveYear(tokenData.access_token);
        setApiData(itemsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Desativa o loading quando a chamada da API é concluída
      }
    };

    fetchActiveYear();
  }, []);

  // Função para lidar com a submissão do formulário
  const handleSubmit = async (e) => {
    // e.preventDefault();
    setErroAno("");
    setErroTrimestre("");
    try {
      setLoading(true); // Ativa o loading
      const data = await postActiveYear(token, ano, referencia, statusGlobal);
      setAnoGlobal(ano);
      setTrimestreGlobal(trimestreGlobal);

      if (data.mensagem === "Periodo alterado com sucesso.") {
        setSucessoAPI("Calendário ativo alterado com sucesso!"); // Define a mensagem de sucesso da API
      } else {
        setErroAPI(`Erro - ${data.mensagem}`);
      }
      // Atualiza os dados após a chamada bem-sucedida da API
      const itemsData = await getActiveYear(token); // Obtém os itens atualizados da API usando o token
      setApiData(itemsData); // Atualiza os dados exibidos
      // Limpa os campos após o sucesso da chamada da API
    } catch (error) {
      // Handle error
      console.error("Erro ao chamar a API:", error);
    } finally {
      setLoading(false); // Desativa o loading quando a chamada da API é concluída
    }
  };

  const distribuidoresMap = {
    DECLUB: "14",
    QUITE: "16",
    LUBTROL: "21",
    PORTOLUB: "26",
    FORMULA: "35",
    LUBPAR: "41",
    PNBLUB: "56",
    BRAZMAX: "61",
    AGRICOPEL: "68",
    "BOA NOVA": "76",
    NACIONAL: "81",
    SPM: "91",
    // TODOS: "0",
  };

  const periodosCheck = () => {
    if (referenciaAutoAval === "1") {
      return `${anoAutoAval}04,${anoAutoAval}05,${anoAutoAval}06`;
    } else if (referenciaAutoAval === "2") {
      return `${anoAutoAval}07,${anoAutoAval}08,${anoAutoAval}09`;
    } else if (referenciaAutoAval === "3") {
      return `${anoAutoAval}10,${anoAutoAval}11,${anoAutoAval}12`;
    } else if (referenciaAutoAval === "4") {
      return `${anoAutoAval}01,${anoAutoAval}02,${anoAutoAval}03`;
    }
  };

  const handleAvaliacaoAutomatica = async () => {
    setLoadingAutoAval(true);

    const codigoDistribuidor = distribuidoresMap[distribuidorAvaliado];
    setCodigoDistribuidorAvaliado(codigoDistribuidor);
    let periodos = ""; // Inicialize periodos com uma string vazia
    let referencias = 1;
    if (periodoAvaliado === "A") {
      // Se a periodicidade for "A" (anual), deixe periodos como uma string vazia
    } else {
      // Caso contrário, calcule os periodos com a função periodosCheck
      periodos = periodosCheck();
      referencias = referenciaAutoAval;
    }
    let bodyApi = {
      distribuidor: distribuidorAvaliado,
      codigo_distribuidor: codigoDistribuidor,
      periodicidade: periodoAvaliado,
      referencia: referencias,
      periodos: periodos,
      ano: anoAutoAval,
    };

    const tokenData = await getToken(emailGlobal, passwordGlobal);
    setToken(tokenData.access_token);

    try {
      const data = await autoAval(tokenData.access_token, bodyApi);
      if (data.mensagem === "processamento finalizado com sucesso") {
        setLoadingAutoAval(false);
        setSucessoApiAuto(
          `O distribuidor ${distribuidorAvaliado} foi avaliado com sucesso.`
        );
      }
      // Faça o que for necessário com os dados da resposta
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
      setSucessoApiAuto(
        `Aconteceu algum erro durante a avaliação. O distribuidor ${distribuidorAvaliado} não foi avaliado com sucesso.`
      );

      setLoadingAutoAval(false);
    }
  };

  const handleAvaliacaoAutomaticaTodos = async () => {
    setLoading2(true);
    setBotaoClicado(true);

    const totalDistribuidores = Object.keys(distribuidoresMap).length;
    let completed = 0;

    try {
      const tokenData = await getToken(emailGlobal, passwordGlobal);
      setToken(tokenData.access_token);

      let periodos = ""; // Inicialize periodos como uma string vazia
      let referencias = 1;

      for (const distribuidor in distribuidoresMap) {
        const codigoDistribuidor = distribuidoresMap[distribuidor];
        setCodigoDistribuidorAvaliado(codigoDistribuidor);
        setCurrentDistribuidor(distribuidor); // Define o distribuidor atual

        // Verifique a periodicidade e defina periodos com base nela
        if (periodoAvaliado === "A") {
          periodos = ""; // Se a periodicidade for "A", defina periodos como uma string vazia
        } else {
          periodos = periodosCheck(); // Caso contrário, obtenha os periodos usando periodosCheck()
          referencias = referenciaAutoAval;
        }

        let bodyApi = {
          distribuidor: distribuidor,
          codigo_distribuidor: codigoDistribuidor,
          periodicidade: periodoAvaliado,
          referencia: referencias,
          periodos: periodos, // Use o valor atualizado de periodos
          ano: anoAutoAval,
        };

        const data = await autoAval(tokenData.access_token, bodyApi);

        if (data.mensagem === "processamento finalizado com sucesso") {
          setSucessoApiAuto(`${distribuidor} foi avaliado com sucesso.`);
        }

        completed++;
        const progressPercentage = Math.round(
          (completed / totalDistribuidores) * 100
        );
        setProgress(progressPercentage); // Atualize a porcentagem concluída
      }
      setLoading2(false);
    } catch (error) {
      console.error("Erro ao chamar a API:", error);
      setSucessoApiAuto(`Ocorreu algum erro durante a avaliação.`);
      setLoading2(false);
    }
  };

  useEffect(() => {
    // Certifique-se de que o 'loading2' esteja ativado apenas quando o botão for clicado e a progressão estiver incompleta
    if (botaoClicado && progress < 100) {
      setLoading2(true);
    } else {
      setLoading2(false);
    }
  }, [progress, botaoClicado]);

  return (
    <Flex justifyContent="space-between">
      {/* Primeira metade */}
      <Box flex="1">
        {loading ? (
          <Center h="100vh">
            <VStack>
              <Spinner size="xl" color="black" thickness="4px" />
              <Text mt="4">Aguarde... </Text>
            </VStack>
          </Center>
        ) : (
          <>
            <Tabs variant="enclosed">
              <TabList mt={3}>
                <Tab>Safra Ativa</Tab>
                <Tab>Avaliar Itens</Tab>
                <Tab>Dashboard</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Heading>Calendário Ativo</Heading>
                  <Divider borderWidth="2px" marginTop={"10px"} />
                  <Box>
                    <Heading as="h4" size="md" mb={2} mt={4}>
                      Ano Safra:
                    </Heading>
                    {apiData ? (
                      <Box>{`${apiData.ano}/${parseInt(apiData.ano) + 1}`}</Box>
                    ) : (
                      <Box>API offline ou sem dados disponíveis</Box>
                    )}
                  </Box>
                  <Box mt={4}>
                    <Heading as="h4" size="md" mb={1}>
                      Trimestre:
                    </Heading>
                    {apiData ? (
                      <Box>{apiData.referencia}</Box>
                    ) : (
                      <Box>API offline ou sem dados disponíveis</Box>
                    )}
                    <Heading as="h4" size="md" mb={2} mt={4}>
                      Status:
                    </Heading>
                    {apiData ? (
                      <Box>{`${apiData.status}`}</Box>
                    ) : (
                      <Box>API offline ou sem dados disponíveis</Box>
                    )}
                  </Box>{" "}
                  <Heading mt={10}>Período de Avaliação</Heading>
                  <Divider borderWidth="2px" marginTop={"10px"} />
                  <form onSubmit={handleSubmit}>
                    <Flex direction="column" alignItems="flex-start">
                      <InputGroup marginTop={"20px"}>
                        <InputLeftAddon children="Status" width="175px" />
                        <Select
                          placeholder="Selecione um status ativo"
                          onChange={(e) => {
                            setStatusGlobal(e.target.value);
                          }}
                          type="text"
                          mb={4}
                          // isInvalid={erroTrimestre} // Aplica estilo de erro se houver um erro
                        >
                          <option value="ATIVO">ATIVO</option>
                          <option value="INATIVO">INATIVO</option>
                        </Select>
                      </InputGroup>
                      <InputGroup marginTop={"20px"}>
                        <InputLeftAddon children="Ano Safra" width="175px" />
                        <Select
                          placeholder="Selecione ano ativo"
                          type="text"
                          onChange={(e) => {
                            setAno(e.target.value);
                            setErroAPI("");
                          }}
                          mb={2}
                          maxLength={4}
                          isInvalid={erroAno} // Aplica estilo de erro se houver um erro
                        >
                          <option value="2023">2023/2024</option>
                          <option value="2024">2024/2025</option>
                          <option value="2025">2025/2026</option>
                          <option value="2026">2026/2027</option>
                          <option value="2027">2027/2028</option>
                          <option value="2028">2028/2029</option>
                          <option value="2029">2029/2030</option>
                        </Select>
                      </InputGroup>
                      {erroAno && <Box color="red">{erroAno}</Box>}
                      <InputGroup marginTop={"20px"}>
                        <InputLeftAddon children="Trimestre" width="175px" />{" "}
                        <Select
                          placeholder="Selecione trimestre ativo"
                          type="number"
                          onChange={(e) => {
                            setReferencia(e.target.value);
                            setErroAPI("");
                          }}
                          mb={2}
                          min="1"
                          max="4"
                          isInvalid={erroTrimestre} // Aplica estilo de erro se houver um erro
                        >
                          <option value="1">1 (abr - mai - jun)</option>
                          <option value="2">2 (jul - ago - set)</option>
                          <option value="3">3 (out - nov - dez)</option>
                          <option value="4">4 (jan - fev - mar)</option>
                        </Select>
                      </InputGroup>

                      {erroTrimestre && <Box color="red">{erroTrimestre}</Box>}
                      {sucessoAPI && <Box color="green">{sucessoAPI}</Box>}
                      {erroAPI && <Box color="red">{erroAPI}</Box>}
                      {/* Exibe a mensagem de sucesso da API */}
                      <Button type="submit" mb={10} mt={4} colorScheme="green">
                        Salvar
                      </Button>
                    </Flex>
                  </form>
                </TabPanel>
                <TabPanel>
                  <Box flex="1">
                    <Heading mt={3}>Avaliar Itens Automáticos</Heading>
                    <Divider borderWidth="2px" marginTop={"10px"} />
                    <Stack>
                      <InputGroup marginTop={"20px"}>
                        <InputLeftAddon children="Distribuidor" width="175px" />
                        <Select
                          onChange={(e) => {
                            setDistribuidorAvaliado(e.target.value);
                          }}
                          placeholder="Selecione um distribuidor"
                          value={distribuidorAvaliado} // Define o valor selecionado no Select
                        >
                          <option value="TODOS">TODOS</option>
                          <option value="AGRICOPEL">AGRICOPEL</option>
                          <option value="BOA NOVA">BOA NOVA</option>
                          <option value="BRAZMAX">BRAZMAX</option>
                          <option value="DECLUB">DECLUB</option>
                          <option value="FORMULA">FORMULA</option>
                          <option value="LUBPAR">LUBPAR</option>
                          <option value="LUBTROL">LUBTROL</option>
                          <option value="NACIONAL">NACIONAL</option>
                          <option value="PNBLUB">PNBLUB</option>
                          <option value="PORTOLUB">PORTOLUB</option>
                          <option value="QUITE">QUITE</option>
                          <option value="SPM">SPM</option>
                        </Select>
                      </InputGroup>
                      <InputGroup marginTop={"20px"}>
                        <InputLeftAddon
                          children="Período de Avaliação"
                          width="175px"
                        />
                        <Select
                          onChange={(e) => {
                            setPeriodoAvaliado(e.target.value);
                          }}
                          placeholder="Selecione um período"
                          value={periodoAvaliado} // Define o valor selecionado no Select
                        >
                          <option value="T">Trimestral</option>
                          {/* <option value="A">Anual</option> */}
                        </Select>
                      </InputGroup>

                      <InputGroup marginTop={"20px"}>
                        <InputLeftAddon children="Ano Safra" width="175px" />
                        <Select
                          type="text"
                          value={anoAutoAval}
                          onChange={(e) => {
                            setAnoAutoAval(e.target.value);
                          }}
                          mb={2}
                          maxLength={4}
                          isInvalid={erroAno} // Aplica estilo de erro se houver um erro
                          // placeholder="Selecione o ano safra"
                        >
                          <option value="">Selecione o ano safra</option>
                          <option value="2023">2023/2024</option>
                          <option value="2024">2024/2025</option>
                          <option value="2025">2025/2026</option>
                          <option value="2026">2026/2027</option>
                          <option value="2027">2027/2028</option>
                          <option value="2028">2028/2029</option>
                          <option value="2029">2029/2030</option>
                        </Select>
                      </InputGroup>
                      <InputGroup marginTop={"20px"}>
                        <InputLeftAddon children="Trimestre" width="175px" />{" "}
                        <Select
                          type="number"
                          value={referenciaAutoAval}
                          onChange={(e) => {
                            setReferenciaAutoAval(e.target.value);
                          }}
                          mb={4}
                          min="1"
                          max="4"
                          isInvalid={erroTrimestre} // Aplica estilo de erro se houver um erro
                          // placeholder="Selecione o trimestre"
                        >
                          <option value="">Selecione o trimestre</option>

                          <option value="1">1 (abr - mai - jun)</option>
                          <option value="2">2 (jul - ago - set)</option>
                          <option value="3">3 (out - nov - dez)</option>
                          <option value="4">4 (jan - fev - mar)</option>
                        </Select>
                      </InputGroup>
                      <InputGroup marginTop={"20px"}>
                        <InputLeftAddon children="Item PEX" width="175px" />{" "}
                        <Input
                          type="text"
                          value={numeroPEX}
                          onChange={(e) => {
                            setNumeroPEX(e.target.value);
                          }}
                          mb={4}
                          min="1"
                          max="4"
                          isInvalid={erroTrimestre} // Aplica estilo de erro se houver um erro
                          isDisabled
                          colorScheme={"gray"} // Define o esquema de cores com base no estado de desabilitado
                          variant="filled" // Escolha o estilo preenchido
                        />
                      </InputGroup>
                      {distribuidorAvaliado !== "TODOS" ? (
                        <>
                          <Button
                            mt="20px"
                            colorScheme="green"
                            onClick={() => {
                              handleAvaliacaoAutomatica();
                              if (sucessoApiAuto !== "") {
                                setSucessoApiAuto("");
                              }
                            }}
                            isDisabled={
                              distribuidorAvaliado === "" ||
                              periodoAvaliado === "" ||
                              loadingAutoAval === true ||
                              anoAutoAval === "" ||
                              referenciaAutoAval === ""
                            }
                            // isDisabled
                          >
                            Avaliar
                          </Button>
                          {loadingAutoAval ? (
                            <Center>
                              <VStack>
                                <Spinner
                                  size="xl"
                                  color="black"
                                  thickness="4px"
                                />
                                <Text mt="4">Por favor aguarde...</Text>
                              </VStack>
                            </Center>
                          ) : (
                            // Exibir mensagem de sucesso quando não estiver carregando
                            <Box
                              color={
                                sucessoApiAuto.includes("erro")
                                  ? "red"
                                  : "green"
                              }
                            >
                              {sucessoApiAuto}
                            </Box>
                          )}
                        </>
                      ) : (
                        <Box mt="20px">
                          <Button
                            colorScheme="green"
                            w="100%"
                            variant="outline"
                            onClick={handleAvaliacaoAutomaticaTodos}
                            // isDisabled={
                            //   loading2 === true ||
                            //   periodoAvaliado === "" ||
                            //   anoAutoAval === "" ||
                            //   referenciaAutoAval === ""
                            // }
                            isDisabled
                            // numeroPEX === "" ||
                          >
                            Avaliar Todos
                          </Button>

                          {loading2 && (
                            <Box mt={4}>
                              <VStack>
                                <CircularProgress
                                  value={progress}
                                  size="120px"
                                  color="green"
                                >
                                  <CircularProgressLabel>{`${progress}%`}</CircularProgressLabel>
                                </CircularProgress>
                                <Box textAlign="center" mt={2}>
                                  Avaliando distribuidor: {currentDistribuidor}
                                </Box>
                              </VStack>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Stack>
                  </Box>{" "}
                </TabPanel>
                <TabPanel>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center" // Alterado de alignContent para alignItems
                  >
                    {/* <Text color="red.300">Em desenvolvimento</Text> */}
                    <GraficoAnexos />
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </Box>

      {/* Segunda metade */}
    </Flex>
  );
};

export default PeriodoAvaliacao;
