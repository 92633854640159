import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useAppContext } from "../context/AppContext";
import { gravaContestacao } from "../api/post-contestacao-api";
import { postAnexo } from "../api/post-anexo-api";

const ContestacaoModal = ({
  isOpen,
  onClose,
  selectedItem,
  dataDaApicontestacoes,
}) => {
  const [contestation, setContestation] = useState("");
  const [response, setResponse] = useState("");
  const [replica, setReplica] = useState("");
  const [treplica, setTreplica] = useState("");
  const [phase, setPhase] = useState("contestation");
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const { globalToken } = useAppContext();
  const [fileBase64, setFileBase64] = useState(null); // Estado para armazenar o arquivo em Base64
  const [isLoading, setIsLoading] = useState(false);
  const [isFileAttached, setIsFileAttached] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [file, setFile] = useState(null); // Estado para o arquivo
  const [trimmedFileName, setTrimmedFileName] = useState("");
  const [matchedItemm, setMatchedItem] = useState({});

  const charLimit = 250; // limite de caracteres
  const currentDate = new Date().toLocaleDateString("pt-BR"); // data atual
  const currentTime = new Date().toLocaleTimeString("pt-BR"); // hora atual

  const { userProfile } = useAppContext();

  useEffect(() => {
    if (dataDaApicontestacoes && selectedItem) {
      const matchedItem = dataDaApicontestacoes.find(
        (item) => item.numero === selectedItem.numero
      );

      setMatchedItem(matchedItem);

      if (matchedItem) {
        const tempHistory = [];

        if (matchedItem.texto_contestacao1) {
          tempHistory.push({
            type: "Contestação",
            message: matchedItem.texto_contestacao1,
            timestamp: `${matchedItem.data_contestacao1} ${matchedItem.hora_contestacao1}`,
          });
        }
        if (matchedItem.texto_contestacao2) {
          tempHistory.push({
            type: "Contestação",
            message: matchedItem.texto_contestacao2,
            timestamp: `${matchedItem.data_contestacao2} ${matchedItem.hora_contestacao2}`,
          });
        }
        if (matchedItem.texto_contestacao3) {
          tempHistory.push({
            type: "Contestação",
            message: matchedItem.texto_contestacao3,
            timestamp: `${matchedItem.data_contestacao3} ${matchedItem.hora_contestacao3}`,
          });
        }

        if (matchedItem.texto_resposta1) {
          tempHistory.push({
            type: "Resposta",
            message: matchedItem.texto_resposta1,
            timestamp: `${matchedItem.data_resposta1} ${matchedItem.hora_resposta1}`,
          });
        }
        if (matchedItem.texto_resposta2) {
          tempHistory.push({
            type: "Resposta",
            message: matchedItem.texto_resposta2,
            timestamp: `${matchedItem.data_resposta2} ${matchedItem.hora_resposta2}`,
          });
        }
        if (matchedItem.texto_resposta3) {
          tempHistory.push({
            type: "Resposta",
            message: matchedItem.texto_resposta3,
            timestamp: `${matchedItem.data_resposta3} ${matchedItem.hora_resposta3}`,
          });
        }

        const intercalatedHistory = [];
        const maxLength = Math.max(
          tempHistory.filter((item) => item.type === "Contestação").length,
          tempHistory.filter((item) => item.type === "Resposta").length
        );

        for (let i = 0; i < maxLength; i++) {
          const contestacao = tempHistory.filter(
            (item) => item.type === "Contestação"
          )[i];
          const resposta = tempHistory.filter(
            (item) => item.type === "Resposta"
          )[i];

          if (contestacao) intercalatedHistory.push(contestacao);
          if (resposta) intercalatedHistory.push(resposta);
        }

        setHistory(intercalatedHistory);
      } else {
        setHistory([]);
      }
      setLoading(false);
    }
  }, [dataDaApicontestacoes, selectedItem]);

  const handleNextPhase = async () => {
    setIsLoading2(true); // Inicia o carregamento

    let body = {
      distribuidor: "FORMULA",
      numero: selectedItem.numero,
      periodo: "Trimestral",
      ano: 2024,
      referencia: 2,
      data_contestacao1: "",
      hora_contestacao1: "",
      texto_contestacao1: "",
      data_contestacao2: "",
      hora_contestacao2: "",
      texto_contestacao2: "",
      data_contestacao3: "",
      hora_contestacao3: "",
      texto_contestacao3: "",
      data_resposta1: "",
      hora_resposta1: "",
      texto_resposta1: "",
      data_resposta2: "",
      hora_resposta2: "",
      texto_resposta2: "",
      data_resposta3: "",
      hora_resposta3: "",
      texto_resposta3: "",
    };

    // Construir o corpo da requisição com base na fase atual
    if (phase === "contestation") {
      body.data_contestacao1 = currentDate; // data atual
      body.hora_contestacao1 = currentTime; // hora atual
      body.texto_contestacao1 = contestation;
      // Adicione lógica para mais contestações se necessário
    } else if (phase === "response") {
      body.data_resposta1 = currentDate; // data atual
      body.hora_resposta1 = currentTime; // hora atual
      body.texto_resposta1 = response;
      // Adicione lógica para mais respostas se necessário
    } else if (phase === "replica") {
      body.data_resposta2 = currentDate; // data atual
      body.hora_resposta2 = currentTime; // hora atual
      body.texto_resposta2 = replica;
      // Adicione lógica para mais réplicas se necessário
    } else if (phase === "treplica") {
      body.data_resposta3 = currentDate; // data atual
      body.hora_resposta3 = currentTime; // hora atual
      body.texto_resposta3 = treplica;
      // Adicione lógica para mais tréplicas se necessário
    }

    // Chamar a API para gravar a contestação ou resposta
    try {
      const result = await gravaContestacao(globalToken, body);
    } finally {
      setIsLoading2(false); // Finaliza o carregamento
    }
  };
  const updateHistory = (type, message) => {
    const newEntry = {
      type,
      message,
      timestamp: new Date().toLocaleString(),
    };
    setHistory((prevHistory) => [...prevHistory, newEntry]);
  };

  if (loading) {
    return <Text>Carregando...</Text>;
  }

  const canContest =
    (userProfile === "Distribuidor" || userProfile === "Administrador") &&
    phase === "contestation" &&
    history.filter((entry) => entry.type === "Contestação").length < 3;

  const canRespond =
    (userProfile === "Avaliador" || userProfile === "Administrador") &&
    phase === "response" &&
    history.some((entry) => entry.type === "Contestação") &&
    history.filter((entry) => entry.type === "Resposta").length < 3;

  const isDisabled =
    history.filter((entry) => entry.type === "Contestação").length >= 3 &&
    history.filter((entry) => entry.type === "Resposta").length >= 3;

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    const maxFileSize = 5 * 1024 * 1024; // 5 MB em bytes

    if (selectedFile) {
      const fileName = selectedFile.name;
      const trimmedFileName =
        fileName.length > 50 ? `${fileName.slice(-50)}` : fileName;

      // Atualiza o estado de trimmedFileName
      setTrimmedFileName(trimmedFileName);

      if (!allowedTypes.includes(selectedFile.type)) {
        alert("Por favor, selecione um arquivo PDF ou JPG/PNG.");
        setFile(null);
        setFileBase64(null); // Limpa o estado Base64 se o arquivo for inválido
        return;
      }

      if (selectedFile.size > maxFileSize) {
        alert("O arquivo deve ter no máximo 5 MB.");
        setFile(null);
        setFileBase64(null); // Limpa o estado Base64 se o arquivo for muito grande
        return;
      }

      setFile(selectedFile.name);

      // Converte o arquivo para Base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result; // O resultado é a string completa
        const pureBase64String = base64String.split(",")[1]; // Remove o prefixo
        setFileBase64(pureBase64String); // Armazena apenas a parte em Base64
      };
      reader.readAsDataURL(selectedFile); // Lê o arquivo como URL de dados
    }
  };

  const handleAnexosContestacao = async () => {
    setIsLoading(true); // Inicia o carregamento
    setIsFileAttached(false); // Reseta a mensagem de arquivo anexado

    const [day, month, year] = matchedItemm.data_contestacao1.split("/");
    const dataContestacaoFormatada = `${year}${month}${day}`;

    const chave = `35${matchedItemm.numero}     ${matchedItemm.periodo}${matchedItemm.ano}${matchedItemm.referencia}${dataContestacaoFormatada}`;

    // console.log("fileBase64", fileBase64);

    try {
      await postAnexo(
        globalToken,
        "ZC5",
        1,
        chave,
        fileBase64,
        trimmedFileName
      );
      setIsFileAttached(true); // Define como true após o arquivo ser anexado com sucesso
    } finally {
      setIsLoading(false); // Finaliza o carregamento
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Contestação do item {selectedItem ? selectedItem.numero : ""}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column" height="60vh">
          <Box
            flex={1}
            overflowY="auto"
            p={4}
            mb={4}
            bg="gray.400"
            borderRadius="md"
          >
            <VStack align="start" spacing={4}>
              {history.length === 0 ? (
                <Text>Nenhum histórico até o momento.</Text>
              ) : (
                history.map((entry, index) => (
                  <Flex
                    key={index}
                    p={2}
                    borderRadius="md"
                    alignSelf={
                      entry.type === "Contestação" ? "flex-start" : "flex-end"
                    }
                    bg={entry.type === "Contestação" ? "blue.50" : "green.50"}
                    maxW="70%"
                    direction="column"
                  >
                    <Text fontWeight="bold">{entry.type}:</Text>
                    <Text>{entry.message}</Text>
                    <Text fontSize="sm" color="gray.500" align="right">
                      {entry.timestamp}
                    </Text>
                  </Flex>
                ))
              )}
            </VStack>
          </Box>

          {phase === "contestation" && canContest && (
            <FormControl>
              <FormLabel>Contestação</FormLabel>
              <Textarea
                placeholder="Escreva sua contestação..."
                value={contestation}
                onChange={(e) => setContestation(e.target.value)}
                maxLength={charLimit}
              />
              <Text textAlign="right" color="gray.500">
                {contestation.length}/{charLimit}
              </Text>
            </FormControl>
          )}

          {phase === "response" && canRespond && (
            <FormControl>
              <FormLabel>Resposta</FormLabel>
              <Textarea
                placeholder="Escreva a resposta..."
                value={response}
                onChange={(e) => setResponse(e.target.value)}
                maxLength={charLimit}
              />
              <Text textAlign="right" color="gray.500">
                {response.length}/{charLimit}
              </Text>
            </FormControl>
          )}

          {phase === "replica" && (
            <FormControl>
              <FormLabel>Réplica</FormLabel>
              <Textarea
                placeholder="Escreva a réplica..."
                value={replica}
                onChange={(e) => setReplica(e.target.value)}
                maxLength={charLimit}
              />
              <Text textAlign="right" color="gray.500">
                {replica.length}/{charLimit}
              </Text>
            </FormControl>
          )}

          {phase === "treplica" && (
            <FormControl>
              <FormLabel>Tréplica</FormLabel>
              <Textarea
                placeholder="Escreva a tréplica..."
                value={treplica}
                onChange={(e) => setTreplica(e.target.value)}
                maxLength={charLimit}
              />
              <Text textAlign="right" color="gray.500">
                {treplica.length}/{charLimit}
              </Text>
            </FormControl>
          )}

          {/* Campo para upload de arquivo */}
          <FormControl>
            <FormLabel>Anexar Arquivo (PDF ou JPG/PNG)</FormLabel>
            <input
              type="file"
              accept=".pdf, .jpg, .jpeg, .png"
              onChange={handleFileChange}
            />
            <Box mt={3}>
              {isLoading ? (
                <Box display="flex" alignItems="center">
                  <Spinner color="red.500" size="sm" mr={2} />
                  <Text>Aguarde, anexando o arquivo...</Text>
                </Box>
              ) : (
                <Button
                  variant="outline"
                  onClick={handleAnexosContestacao}
                  colorScheme="red"
                >
                  Anexar
                </Button>
              )}
              {isFileAttached && (
                <Text mt={3} color="green.500">
                  Arquivo anexado com sucesso!
                </Text>
              )}
            </Box>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={handleNextPhase}
            colorScheme="blue"
            mr={3}
            isDisabled={
              isLoading ||
              isDisabled ||
              (phase === "contestation" && !canContest) ||
              (phase === "response" && !canRespond)
            }
          >
            {isLoading2 ? (
              <Box display="flex" alignItems="center">
                <Spinner size="sm" color="white" mr={2} />
                Enviando...
              </Box>
            ) : (
              "Enviar"
            )}
          </Button>
          <Button onClick={onClose} variant="ghost">
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ContestacaoModal;
