export async function gravaContestacao(token, body) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify(body);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const apiUrl = `https://decminas-tst-protheus.totvscloud.com.br:43827/rest_tst/INT_SHELL_PEX_GRAVACONTESTACAO`;

  try {
    const response = await fetch(apiUrl, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting contestação:", error);
  }
}
