import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Button,
  Center,
  Spinner,
  Heading,
  Divider,
  VStack,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FaBullseye,
  FaClipboard,
  FaCommentDots,
  FaUserTie,
  FaExclamation,
} from "react-icons/fa";
import { useAppContext } from "../context/AppContext";
import { getToken } from "../api/get-token-api";
import { getAllItemsPexWithPoints } from "../api/get-items-pontuacao-api";
import { getAllItemsPex } from "../api/get-items-pex-api";
import { validCredentials } from "../helpers/mock/validCredentials-mock"; // Import the validCredentials file
import ContestacaoModal from "../components/modal-contestacao";
import { getContestacoes } from "../api/get-contestacoes-api";

const SelecionarPeriodoPontuacao = () => {
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [data, setData] = useState([]);
  const [periodoAtivo, setPeriodoAtivo] = useState("Trimestral");
  const { anoGlobal, trimestreGlobal, setGlobalToken } = useAppContext();
  const { globalToken, emailGlobal, passwordGlobal, distribuidor } =
    useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const [emailBody, setEmailBody] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [contestacoesState, setContestacoesState] = useState([]);

  useEffect(() => {
    let paramForGetItemsPex = 2;

    if (
      anoGlobal === "2023" &&
      (trimestreGlobal === "3" || trimestreGlobal === "4")
    ) {
      paramForGetItemsPex = 1;
    }

    const fetchData = async () => {
      setIsLoadingTable(true);

      const tokenData = await getToken(emailGlobal, passwordGlobal);
      setGlobalToken(tokenData.access_token);
      const data = await getAllItemsPexWithPoints(
        tokenData.access_token,
        periodoAtivo,
        distribuidor || "FORMULA",
        anoGlobal,
        trimestreGlobal,
        paramForGetItemsPex
      );

      const dataItens = await getAllItemsPex(
        tokenData.access_token,
        paramForGetItemsPex
      );

      const contestacoes = await getContestacoes(
        distribuidor || "FORMULA",
        periodoAtivo,
        anoGlobal,
        trimestreGlobal,
        tokenData.access_token
      );
      await setContestacoesState(contestacoes);

      // Supondo que data retorna os valores para os campos de contestação

      const filteredData = data.filter(
        (item) =>
          item.observacao &&
          item.observacao.trim() !== "" &&
          item.pontuacao_maxima !== item.pontuacao_real
      );

      // Criar um mapeamento para os itens da segunda API
      const itemsMap = dataItens.reduce((acc, item) => {
        acc[item.numero] = item; // Usa o número como chave
        return acc;
      }, {});

      // Combinar dados com base no número
      const combinedData = filteredData.map((item) => {
        const correspondingItem = itemsMap[item.numero]; // Encontra o item correspondente
        return {
          ...item,
          ...(correspondingItem ? correspondingItem : {}), // Combina os dados se existir
        };
      });

      // Ordenando os dados combinados
      const sortedData = combinedData.sort((a, b) => {
        const aParts = a.numero.split(".").map(Number);
        const bParts = b.numero.split(".").map(Number);
        for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
          const aValue = aParts[i] || 0;
          const bValue = bParts[i] || 0;
          if (aValue !== bValue) {
            return aValue - bValue;
          }
        }
        return 0; // São iguais
      });

      setData(sortedData);
      setIsLoadingTable(false);
    };

    fetchData();
  }, [periodoAtivo]);

  // Função para abrir o modal com o item selecionado
  const handleContestar = (item) => {
    setSelectedItem(item); // Salva o item selecionado no estado
    setEmailBody(""); // Limpa o campo de contestação ao abrir o modal
    onOpen(); // Abre o modal
  };

  return (
    <Box>
      <Heading>Acompanhamento e Contestações - {distribuidor}</Heading>
      <Divider borderWidth="2px" marginTop={"10px"} />

      <Flex gap={3} my="4">
        <Button
          onClick={() => setPeriodoAtivo("Trimestral")}
          colorScheme={periodoAtivo === "Trimestral" ? "yellow" : "gray"}
        >
          Trimestral
        </Button>
        <Button
          onClick={() => setPeriodoAtivo("Anual")}
          colorScheme={periodoAtivo === "Anual" ? "yellow" : "gray"}
        >
          Anual
        </Button>
      </Flex>

      {isLoadingTable ? (
        <Center h="100vh">
          <VStack>
            <Spinner size="xl" color="black" thickness="4px" />
            <Text mt="4">Aguarde... </Text>
          </VStack>
        </Center>
      ) : (
        <Box>
          <VStack spacing={4} align="stretch">
            {data.length === 0 ? (
              <Text>Nenhum dado disponível.</Text>
            ) : (
              data.map((item) => (
                <Box key={item.chave} p={4} borderWidth={1} borderRadius="lg">
                  <Flex justify="space-between" align="center">
                    <Text fontWeight="bold" fontSize="large">
                      <Icon as={FaClipboard} color="blue.500" mr={2} />
                      {item.numero}
                    </Text>
                    {/* Botão de contestar ao lado direito */}
                    <Button
                      leftIcon={<Icon as={FaExclamation} />}
                      colorScheme="red"
                      onClick={() => handleContestar(item)}
                    >
                      CONTESTAR
                    </Button>
                  </Flex>
                  <Divider />
                  <Text mt={2}>
                    <Icon as={FaUserTie} color="green.500" mr={2} />
                    Responsável: {item.capitulo}
                  </Text>
                  <Text>
                    <Icon as={FaBullseye} color="yellow.500" mr={2} />
                    Pontuação Máxima: {item.pontuacao_maxima}
                  </Text>
                  <Text>
                    <Icon as={FaBullseye} color="yellow.500" mr={2} />
                    Pontuação Obtida: {item.pontuacao_real}
                  </Text>
                  <Text>
                    <Icon as={FaBullseye} color="yellow.500" mr={2} />
                    Atingimento: {item.atingimento}%
                  </Text>
                  <Box
                    bg="gray.100"
                    p={3}
                    borderRadius="10px"
                    mt={2}
                    mb={2}
                    min="33vw"
                  >
                    <Icon as={FaCommentDots} color="gray.500" mr={2} />
                    <Text>
                      <strong>Observação:</strong> {item.observacao}
                    </Text>
                  </Box>
                </Box>
              ))
            )}
          </VStack>
        </Box>
      )}

      {/* Modal de contestação */}
      <ContestacaoModal
        isOpen={isOpen}
        onClose={onClose}
        selectedItem={selectedItem} // Aqui você deve garantir que selectedItem não é undefined
        emailBody={emailBody}
        setEmailBody={setEmailBody}
        dataDaApicontestacoes={contestacoesState}
      />
    </Box>
  );
};

export default SelecionarPeriodoPontuacao;
